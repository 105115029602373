.gm-type, .gm-payrate, .gm-certificate, .gm-license,
.job-giftcard, .job-giftcard-issuer, .job-giftcard-date,
.gm-certificate-date {
  display: none;
}

li.pointer-cursor:hover {
  cursor: pointer;
}

.job-date, .job-note {
  display: none;
}

span.select2 {
  margin-right: 16px !important;
}

a.code {
  color: #e83e8c;
}

