
.sidebar {
  background-color: #343a40 !important;
  bottom: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  left: 0;
  padding: 48px 0 0; /* Height of navbar */
  position: fixed;
  top: 0;
  z-index: 100; /* Behind the navbar */

  .nav-item {
    .nav-link {
      color: rgba(255, 255, 255, .6);

      &.active {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;

    span {
      color: rgba(255, 255, 255, .2);
    }
  }

  .sidebar-sticky {
    height: calc(100vh - 48px);
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    padding-top: .5rem;
    position: relative;
    top: 0;
  }

  .feather {
    color: rgba(255, 255, 255, 1);
    height: 16px;
    margin-right: 4px;
    vertical-align: text-bottom;
    width: 16px;
  }
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}