body {
  font-size: .875rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

th:first-child, td:first-child {
  left: 210px;
  position: sticky;
  white-space: nowrap
}

td:first-child {
  background-color: #d3d3d3;
  width: 200px;
}

.flex-even {
  flex: 1;
}
input.resize-on-focus {
  width: 50px;
  transition: .3s linear;

  &:focus {
    width: 250px;
    outline: none;
  }
}

